<template>
  <img :src="imagePath" :alt="altText" :height="height" :width="width" />
</template>
<script>
// import { fileApi } from "@/http/http.config";
// import gql from "graphql-tag";

export default {
  name: "FileViewerLocation",
  props: {
    imageId: {
      type: String,
      required: false,
      default: "",
    },
    height: {
      type: String,
      required: false,
      default: "",
    },
    width: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["loading"],
  data: function () {
    return {
      fileDetail: { path: "", name: "" },
    };
  },
  computed: {
    altText() {
      if (!this.fileDetail || !this.fileDetail.name) {
        return "Unknown";
      } else {
        return this.fileDetail.name;
      }
    },
    imagePath() {
      this.$emit("loading", true);
      if(this.$props.imageId || this.$props.imageId != "") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.$props.imageId
      }
      if (!this.fileDetail || !this.fileDetail.path) {
        this.$emit("loading", false);
        return require("@/assets/images/logos/walmart_logo2.png");
      } else {
        return `${this.fileDetail.path}`;
      }
    },
  },
  mounted() {
      // console.log("inside mounted of new file viewer location");
    // this.getFile();
  },
  methods: {
    /*async getFile(){
      await restApi.post('/api/getFileURL',encodeWithParam({fileID: this.$props.imageId})).then((responseData)=>{
        // console.log("file viewer responseData", responseData);
        if(responseData.data.result)

          this.fileDetail = responseData?.data?.result[0];
          // console.log("fileDetail after api", this.fileDetail);
      });
    },*/
    // getPath() {
    //   const access_token = localStorage.getItem("access_token");
    //   // TODO: Set the url to be dynamic
    //   return fileApi
    //     .get(`https://app.dev.ddiwork.com/api/files?=${this.$props.imageId}`, {
    //       headers: { Authorization: `Bearer ${access_token}`, withCredentials: true, "Access-Control-Allow-Origin": "*", Accept: "*/*" },
    //     })
    //     .then(({ data }) => {
    //       this.$emit("loading", false);
    //       this.$log.debug(data);
    //     })
    //     .catch((err) => this.$log.error(err));
    // },
  },
  // apollo: {
  //   fileDetail: {
  //     query: gql`
  //       query getFileDetails($id: uuid!) {
  //         files_by_pk(id: $id) {
  //           id
  //           name
  //           path
  //           public
  //         }
  //       }
  //     `,
  //     variables() {
  //       return {
  //         id: this.$props.imageId,
  //       };
  //     },
  //     update: (data) => data.files_by_pk,
  //     error: () => {},
  //     skip() {
  //       return !this.$props.imageId;
  //     },
  //   },
  // },
};
</script>
